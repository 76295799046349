import React from 'react'
import {
  Cat3d,
  Fox,
  IndexTeamIcon1,
  IndexTeamIcon2,
  IndexTeamIcon3,
  IndexTeamIcon4,
  IndexTeamIcon5,
  IndexTeamIcon6,
  IndexTeamIcon7,
  IndexTeamIcon8,
  IndexTeamIcon9,
  IndexTeamIcon10,
  QingjiaoCloud,
  Raysync,
  Renderbus,
  RaysyncEn,
  Doulong,
  Raylink,
} from '../components/icons'

import IndexCompanyListCn1 from '../images/index/index-company-list-cn-1.png'
import IndexCompanyListCn2 from '../images/index/index-company-list-cn-2.png'
import IndexCompanyListCn3 from '../images/index/index-company-list-cn-3.png'
import IndexCompanyListCn4 from '../images/index/index-company-list-cn-4.png'
import IndexCompanyListCn5 from '../images/index/index-company-list-cn-5.png'
import IndexCompanyListCn6 from '../images/index/index-company-list-cn-6.png'
import IndexCompanyListCn7 from '../images/index/index-company-list-cn-7.png'
import IndexCompanyListEn1 from '../images/index/index-company-list-en-1.png'
import IndexCompanyListEn2 from '../images/index/index-company-list-en-2.png'
import IndexCompanyListEn3 from '../images/index/index-company-list-en-3.png'
import IndexCompanyListEn4 from '../images/index/index-company-list-en-4.png'
import IndexCompanyListEn5 from '../images/index/index-company-list-en-5.png'
import IndexCompanyListEn6 from '../images/index/index-company-list-en-6.png'
import IndexCompanyListEn7 from '../images/index/index-company-list-en-7.png'

export const CnProductionList = [
  {
    icon: <Renderbus />,
    title: 'index_product_renderbus_title',
    intro: 'index_product_renderbus_intro',
    linkText: 'index_product_renderbus_more',
    linkTo: 'https://www.renderbus.com/',
    scale: 0.8,
    index: 1,
  },
  {
    icon: <QingjiaoCloud />,
    title: 'index_product_qingjiao_title',
    intro: 'index_product_qingjiao_intro',
    linkText: 'index_product_qingjiao_more',
    linkTo: 'https://www.qingjiaocloud.com/',
    scale: 0.8,
    index: 3,
  },
  // {
  //   icon: <Raylink />,
  //   title: 'index_product_raylink_title',
  //   intro: 'index_product_raylink_intro',
  //   linkText: 'index_product_raylink_more',
  //   linkTo: 'https://www.raylink.live',
  //   index: 5,
  // },
  {
    icon: <Raysync />,
    title: 'index_product_raysync_title',
    intro: 'index_product_raysync_intro',
    linkText: 'index_product_raysync_more',
    linkTo: 'https://www.raysync.cn/',
    index: 2,
  },
  {
    icon: <Cat3d />,
    title: 'index_product_3dcat_title',
    intro: 'index_product_3dcat_intro',
    linkText: 'index_product_3dcat_more',
    linkTo: 'https://www.3dcat.live/',
    index: 4,
  },
]

export const EnProductionList = [
  {
    icon: <Fox />,
    title: 'index_product_fox_title',
    intro: 'index_product_fox_intro',
    linkText: 'index_product_fox_more',
    linkTo: 'https://www.foxrenderfarm.com/',
    index: 1,
  },
  {
    icon: <QingjiaoCloud />,
    title: 'index_product_qingjiao_title',
    intro: 'index_product_qingjiao_intro',
    linkText: 'index_product_qingjiao_more',
    linkTo: 'https://www.qingjiaocloud.com/',
    scale: 0.8,
    index: 3,
  },
  {
    icon: <RaysyncEn />,
    title: 'index_product_raysync_title',
    intro: 'index_product_raysync_intro',
    linkText: 'index_product_raysync_more',
    linkTo: 'https://www.raysync.io/',
    index: 2,
  },
  {
    icon: <Cat3d />,
    title: 'index_product_3dcat_title',
    intro: 'index_product_3dcat_intro',
    linkText: 'index_product_3dcat_more',
    linkTo: 'https://www.3dcat.live/',
    index: 4,
  },
]

export const CnCompanyImageList = [
  IndexCompanyListCn1,
  IndexCompanyListCn2,
  IndexCompanyListCn3,
  IndexCompanyListCn4,
  IndexCompanyListCn5,
  IndexCompanyListCn6,
  IndexCompanyListCn7,
]
export const EnCompanyImageList = [
  IndexCompanyListEn1,
  IndexCompanyListEn2,
  IndexCompanyListEn3,
  IndexCompanyListEn4,
  IndexCompanyListEn5,
  IndexCompanyListEn6,
  IndexCompanyListEn7,
]

export const CnCompanyFieldList = [
  '三维动画电影和动画剧制作',
  '影视后期特效',
  '建筑可视化/建筑视觉表现',
  '主题乐园特种电影',
  '汽车设计可视化',
  '工业产品设计可视化',
  '云游戏服务平台',
  '家装设计工具',
  '高等教育仿真实验',
  '智慧城市/GIS视觉呈现',
]

export const EnCompanyFieldList = [
  '3D Animation',
  'VFX',
  'Archviz',
  'Special Effects Films',
  'Automotive Visualization',
  'Manufacturing Simulation',
  'Cloud Gaming',
  'Online Education',
  'GIS Visualization',
]

export const TeamInfoList = [
  {
    title: 'index_team_welfare.annual_leave',
    icon: <IndexTeamIcon1 />,
  },
  {
    title: 'index_team_welfare.insurance',
    icon: <IndexTeamIcon2 />,
  },
  {
    title: 'index_team_welfare.outings',
    icon: <IndexTeamIcon3 />,
  },
  {
    title: 'index_team_welfare.bonus',
    icon: <IndexTeamIcon4 />,
  },
  {
    title: 'index_team_welfare.health',
    icon: <IndexTeamIcon5 />,
  },
  {
    title: 'index_team_welfare.promotions',
    icon: <IndexTeamIcon6 />,
  },
  {
    title: 'index_team_welfare.holiday',
    icon: <IndexTeamIcon7 />,
  },
  {
    title: 'index_team_welfare.incentive',
    icon: <IndexTeamIcon8 />,
  },
  {
    title: 'index_team_welfare.benefits',
    icon: <IndexTeamIcon9 />,
  },
  {
    title: 'index_team_welfare.clubs',
    icon: <IndexTeamIcon10 />,
  },
]

export const ContactShenzhenList = [
  '客户咨询电话：400-880-8120',
  '24小时热线：400-003-4560',
  '传真：0755-82557919',
  '电邮：service@rayvision.com',
  '地址：深圳市南山区商华路2号阳光科创中心B座17层 ',
]

export const ContactInfoList = [
  {
    title: '渲染大客户合作',
    info: ['18612698101（冯先生）', 'weller@rayvision.com'],
    width: '33.3%',
  },
  {
    title: '镭速传输产品授权服务商',
    info: ['18948780646（黄先生）', '13510709299（郑先生）'],
    width: '33.3%',
  },
  {
    title: '青椒云电脑合作',
    info: ['18681593969（肖先生）', 'gordon@rayvision.com'],
    width: '33.3%',
  },
  {
    title: '3DCAT实时渲染解决方案授权服务商',
    info: ['13510709299（郑先生）', '18948780646（黄先生）'],
    width: '90%',
    marTop: '40px',
  },
  // {
  //   title: 'RayLink远程控制软件',
  //   info: ['18681593969（肖先生）', 'gordon@rayvision.com'],
  //   width: '66.3%',
  //   marTop: '40px',
  // },
  {
    title: '北京办事处',
    info: [
      '18948780641（王小姐）',
      'tobby@rayvision.com',
      '北京市朝阳区管庄路东方华瑞科技园南区C座705',
    ],
    hiddenOnMobile: true,
    width: '33.3%',
  },
  {
    title: '上海办事处',
    info: [
      '13402051543（周先生）',
      'tomzhou@rayvision.com',
      '上海市嘉定区南翔镇金迈路515弄冰风科技园1号楼6层605室',
    ],
    hiddenOnMobile: true,
    width: '34.3%',
  },
  {
    title: '成都办事处',
    info: [
      '18948780640（谢先生）',
      'mark@rayvision.com',
      '成都市高新区盛和一路88号康普雷斯B座1605',
    ],
    hiddenOnMobile: true,
    width: '32.3%',
  },
]
