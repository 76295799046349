import styled, { keyframes, css } from 'styled-components'
import { color, fontSize, spacing, transition, rotate, shape, Media, hideAtMobile } from '../theme'

const FadeInDuration = 1

const FadeInWithY = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

export const PageScrollTime = 600

export const FadeInItem = css`
  ${(p) =>
    p.isEnter &&
    css`
      animation: ${FadeInWithY} ${FadeInDuration}s
        ${(p.index || 0) * (p.spacing || 0.3) + (p.delay || 0.5)}s both;
    `}
`
export const FadeInItemContainer = styled.div`
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft + 'px' : '0px')};
  ${FadeInItem}
  width: ${(p) => p.containerWidth};
  box-sizing: border-box;
  h5 {
    white-space: nowrap;
    margin: ${(p) => `${p.marTop ? p.marTop : '60px'} 0 ${spacing.small} 0`};
  }
  ${(p) => p.hiddenOnMobile && hideAtMobile}
  ${Media.phone`
    padding-left: 0px;
  `}
`

export const ViewportContainer = styled.div`
  overflow: hidden;
  height: 100vh;
  color: white;
  background-color: #162635;
  font-family: Source Han Sans CN, Source Han Sans SC, Lato;
`

export const PageContainer = styled.div`
  transition: ${transition('transform', PageScrollTime)};
  display: flex;
  flex-direction: column;
  position: relative;
`

export const IndexSection = styled.div`
  display: flex;
  position: relative;
  height: 100vh;
  z-index: 1;
`
export const TitleSection = styled(IndexSection)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 45px;
`

export const TitleContainer = styled.div`
  width: 610px;
  border: 10px solid #2bbfa566;
  padding-top: ${spacing.base};
  text-align: center;
  h3 {
    font-size: ${fontSize.large};
    line-height: ${spacing.large};
    margin: 17px 0 0;
    font-weight: 300;
  }
  h1 {
    margin-top: ${spacing.small};
    font-size: ${fontSize.giant};
    line-height: ${fontSize.huge};
    font-weight: bold;
    strong {
      color: ${color.primaryLight};
    }
  }
  p {
    margin: ${spacing.base} 0;
    font-size: 20px;
  }
  ${Media.phone`
    width: 100%;
    h3 {
      font-size: ${fontSize.medium};
      line-height: 23px;
    }
    h1 {
      font-size: 45px;
      line-height: 54px;
    }
    p {
      font-size: ${fontSize.base};
    }
  `}
`

export const MouseIconContainer = styled.div`
  margin: 100px auto 0;
  z-index: 1;
  svg {
    height: 50px;
  }
`

export const SectionBackgroundVideo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const PageSidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`

export const SectionIntroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 100px 0 100px;
  width: 100%;
  overflow: hidden;
  ${Media.phone`
    padding-top: 100px;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 20px 0 50px;
  `}
`

export const SectionAboutIntroContainer = styled(SectionIntroContainer)`
  margin: 0 90px 0 100px;
  ${Media.phone`
    margin: 0 35px 0 50px;
  `}
`

export const SectionRayvisionLine = styled.div`
  margin: ${spacing.base} 0;
  ::before {
    content: '';
    display: inline-block;
    height: 6px;
    width: 100px;
    margin-right: ${spacing.small};
    background-color: ${color.yellow};
  }
  ::after {
    content: '';
    display: inline-block;
    height: 6px;
    width: 200px;
    background-color: ${color.primaryDark};
  }
  ${Media.phone`
    margin: ${spacing.small} 0;
    ::before {
      height: 3px;
      width: 50px;
    }
    ::after {
      height: 3px;
      width: 100px;
    }
  `}
`

export const SectionIntroContent = styled.div`
  padding: 200px 110px 0 0;
  width: 680px;
  max-width: 100%;
  flex-grow: 1;
  .data-source {
    color: #dddddd;
  }
  h1,
  h2 {
    font-size: ${fontSize.giant};
    line-height: 80px;
    margin: 0 0 ${spacing.small};
    font-weight: bold;
  }
  p {
    font-size: ${fontSize.base};
    line-height: ${spacing.large};
    margin: 0 0 ${spacing.base};
    font-weight: 400;
  }
  ${(p) =>
    p.isEnter &&
    css`
      h2 {
        animation: ${FadeInWithY} ${FadeInDuration}s 0.5s both;
      }
      h1 {
        animation: ${FadeInWithY} ${FadeInDuration}s 0.8s both;
      }
      p {
        animation: ${FadeInWithY} ${FadeInDuration}s 1.1s both;
      }
      ${SectionRayvisionLine} {
        animation: ${FadeInWithY} ${FadeInDuration}s 1.4s both;
      }
    `}
  ${Media.laptop`
    padding: 140px 60px 0 0;
  `}
  ${Media.phone`
    padding: 0;
    flex-grow: 0;
    h2 {
      font-size: ${fontSize.medium};
      line-height: 1;
    }
    h1 {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
    p {
      margin: 0 0 ${spacing.small};
      font-size: ${fontSize.mini};
      line-height: ${fontSize.medium};
    }
  `}
`

export const SectionCaseImageContainer = styled.div`
  margin-top: 90px;
  padding-right: 90px;
  flex-shrink: 0;
  max-width: 60%;
  ${FadeInItem};
  ${Media.phone`
    margin: 0;
    padding: 0;
    max-width: 100%;
  `}
`

export const ProductionOutterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const ProductionContainer = styled.div`
  width: 980px;
  max-width: 100%;
  column-count: 2;
  column-gap: ${spacing.base};
  ${Media.phone`
    width: 100%;
    height: 100%;
    column-gap: ${spacing.small};
  `}
`

export const ProductionCard = styled.div`
  width: 100%;
  height: 250px;
  margin: 5px 0;
  padding: ${spacing.plus} ${spacing.large} ${spacing.base} ${spacing.plus};
  border-radius: ${shape.radiusLarge};
  background-color: ${color.panel};
  text-align: left;
  display: inline-block;
  border-radius: 10px;
  transition: ${transition('background-color')};
  svg {
    height: ${spacing.large};
  }
  h5 {
    margin: ${spacing.base} 0 ${spacing.small};
    line-height: 1;
    font-size: ${fontSize.base};
    font-weight: 400;
  }
  p {
    margin: 0 0 ${spacing.large};
    font-size: ${fontSize.small};
    line-height: ${fontSize.large};
    font-weight: 400;
  }
  a {
    color: ${color.primaryDark};
    font-size: ${fontSize.small};
    font-weight: 400;
    cursor: pointer;
    border-radius: 18px;
    transition: ${transition(['color', 'background-color', 'padding', 'box-shadow'])};
  }
  :hover {
    background-color: rgb(51 51 51 / 91%);
    a {
      padding: 10px 50px;
      background-color: #02a6bc;
      color: white;
      box-shadow: 0px 10px 16px 4px rgba(2, 166, 188, 0.3);
    }
  }
  ${FadeInItem};
  ${Media.laptop`
    padding: ${spacing.base};
  `}
  ${Media.phone`
    height: fit-content;
    padding: ${spacing.base} 0px ${spacing.small} ${spacing.small};
    svg {
      height: ${({ size }) => (size ? `${size}` : '24px')};
      path {
        transform: ${({ scale }) => (scale ? `scale(${scale})` : 'scale(1)')};
      }
    }
    h5 {
      margin: 0 0 8px;
      font-size: ${fontSize.tiny};
      text-align: left;
      line-height: 15px;
    }
    p {
      display: none;
    }
    a {
      display: none;
    }
  `}
`

export const CompanyFieldContainer = styled.ul`
  margin: ${spacing.large} 0 0 auto;
  font-size: ${fontSize.mini};
  line-height: ${fontSize.large};
  text-align: right;
`

export const CompanyContainer = styled.div`
  width: 980px;
  max-width: 100%;
  height: 550px;
  margin-top: 170px;
  padding: ${spacing.plus};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: ${shape.radiusLarge};
  background-color: ${color.panel};
  ${FadeInItem}
  img {
    width: 100%;
  }
  ${hideAtMobile}
`

export const TeamContainer = styled.div`
  width: 980px;
  height: 480px;
  margin-top: 170px;
  padding: 114px 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  border-radius: ${shape.radiusLarge};
  background-color: ${color.panel};
  ${FadeInItem}
  ${hideAtMobile}
`

export const TeamCard = styled.div`
  width: 20%;
  height: 100px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  svg {
    max-width: 45px;
    max-height: 40px;
  }
  p {
    height: ${fontSize.super};
    padding-top: ${spacing.small};
    font-size: ${fontSize.base};
    text-align: center;
  }
  ${FadeInItem};
`

export const TeamJoinLink = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: ${spacing.large};
  transition: ${transition('color')};
  ${FadeInItem};
  span {
    display: inline-block;
    margin-right: 26px;
  }
  svg {
    width: 152px;
    g {
      transition: ${transition('stroke')};
    }
  }
  :hover {
    color: ${color.primaryLight};
    svg g {
      stroke: ${color.primaryLight};
    }
  }
  ${Media.phone`
    font-size: ${fontSize.mini};
    span {
      margin-right: ${spacing.small};
    }
    svg {
      width: 110px;
    }
  `}
`

export const CompanyImage = styled.img`
  ${FadeInItem}
`

export const ContactShenzhenContainer = styled.div`
  h5 {
    line-height: 1;
    margin: ${spacing.plus} 0 ${spacing.base};
    font-weight: bold;
    font-size: ${fontSize.large};
  }
  ul {
    font-size: ${fontSize.base};
    line-height: ${spacing.large};
    font-weight: 400;
  }
  ${(p) =>
    p.isEnter &&
    css`
      h5 {
        animation: ${FadeInWithY} ${FadeInDuration}s 1.1s both;
      }
    `}
  ${Media.phone`
    h5 {
      margin: ${spacing.base} 0 ${spacing.small};
      font-size: ${fontSize.base};
    }
    ul {
      font-size: ${fontSize.tiny};
      line-height: 15px;
    }
  `}
`

export const ContactContainer = styled.div`
  width: 980px;
  max-width: 100%;
  height: min-content;
  margin-top: 190px;
  padding: 0 50px 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-radius: ${shape.radiusLarge};
  background-color: ${color.panel};
  h5 {
    font-size: ${fontSize.medium};
    font-weight: bold;
    word-wrap: break-word;
    white-space: normal;
  }
  p {
    font-size: ${fontSize.small};
    line-height: ${fontSize.large};
    font-weight: 400;
  }
  ${FadeInItem}
  ${Media.laptop`
    margin-top: 100px;
  `}
  ${FadeInItemContainer} {
    &:nth-child(5) {
      height: 191px;
    }
  }
  ${Media.phone`
    margin-top: 0;
    padding: 5px 5px 15px;
    ${FadeInItemContainer} {
      &:nth-child(5) {
        height: unset;
      }
    }
    > div {
      width: 50%;
      padding-right: 5px;
      :last-of-type {
        width: 100%;
      }
    }
    h5 {
      margin: ${spacing.small} 0 8px;
      font-size: ${fontSize.mini};
    }
    p {
      font-size: ${fontSize.tiny};
      line-height: ${fontSize.base};
      word-break: break-all;
    }
  `}
`

export const PageNavContainer = styled.div`
  margin-left: ${spacing.large};
  display: flex;
  flex-direction: column;
  z-index: 1;
  ${Media.phone`
    margin-left: ${spacing.small};
  `}
`

export const PageNavIndex = styled.div`
  position: relative;
  margin-bottom: 45px;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  color: ${(p) => (p.activited ? color.primary : 'white')};
  transition: ${transition('color')};
  ::before,
  ::after {
    content: '';
    position: absolute;
    opacity: ${(p) => (p.activited ? '1' : '0')};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    transition: ${transition('opacity')};
  }
  ::before {
    border: 2px solid #646c7a;
  }
  ::after {
    border-top: 3px solid ${color.primary};
    border-right: 2px solid transparent;
    /* border-bottom: 2px solid transparent; */
    border-left: 2px solid ${color.primary};
    animation: ${rotate} 1s linear infinite;
  }
  ${Media.phone`
    height: 31px;
    width: 31px;
    font-size: ${fontSize.mini};
  `}
`

export const PageNavArrowContainer = styled.div`
  margin: 55px 0 0 ${spacing.base};
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  ${hideAtMobile}
`

export const PageNavArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${spacing.large};
  width: 60px;
  background-color: black;
  cursor: pointer;
  transition: ${transition('background-color')};
  :hover {
    background-color: ${color.primaryLight};
  }
  ::after {
    content: '';
    width: 13px;
    height: 13px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: ${(p) => `rotate(${p.direction === 'next' ? '45deg' : '-135deg'})`};
  }
`
